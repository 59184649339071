@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&family=Pangolin&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rock+Salt&display=swap');


body {
    background-image: linear-gradient(65deg, rgb(169, 160, 118), rgba(195, 156, 109, 0.6));
    background-color: rgb(238, 149, 71);
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 450;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


form {
    margin: 1rem 1.5rem 2rem;
    padding: 2rem;
    background-image: url("../public/sand.jpg");
    border-radius: 25px;
    color: rgb(12, 91, 16)
}

input[type=number] {
    border-radius: 10px;
    border: none;
    text-align: right;
    padding-right: .3rem;
    width: 4rem;
    float: right;
    font-size: 1rem;
    -moz-appearance: textfield;
    background-color: rgb(255, 255, 255, 0.6);
}

input[type=number]:hover {
    background-color: rgb(255, 255, 255, 0.9);
}

b {
    font-family: "Rock Salt", cursive;
    color: rgb(83, 55, 3);
    margin: 0rem .5rem;
}

input[type=submit] {
    margin-top: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .1rem;
    transition-duration: 0.4s;
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    border-style: solid;
    background-color: rgb(253, 185, 51, 0.3);
    border-color: rgb(255, 255, 255, 0.6);
    color: rgb(255, 255, 255, 0.9);
    border-radius: 25px;
    width: fit-content;
}

input[type=submit]:hover {
    opacity: 0.9;
    background-color: white;
    color: rgb(99, 69, 9);
}

.recipe-wrapper{
    margin: .5rem 1.5rem 2rem;
    border-radius: 25px;
    padding: 0.9rem;
    background-color: rgb(116, 248, 252, 0.7);
    background-image: url(../public/dolphine.svg);
    background-position: 90% 20%;
    background-size: 25rem 25rem;
    background-repeat:repeat-x;
    color:rgb(4, 75, 79)
}

.recipe {
    margin: 1rem 2rem 1rem;
}
.recipe li {
    padding: .3rem;
    list-style: none;
    transition-duration: 0.4s;
    width: 50%;
    background-color: rgb(255, 255, 255, 0.4);
}

.recipe li::before {
    content: '';
    display: inline-block;
    height: 15px;
    width: 15px;
    background-image: url("../public/star.svg");
    background-size: cover;
    margin-right: .5rem;
}

.recipe li:hover {
    margin-left: -1rem;
}

.recipe h3, .recipe-name {
    font-size: 150%;
    font-family: "Kode Mono", monospace;
    /* color: rgb(77, 60, 60); */
    color: rgb(19, 138, 143);
}

.recipe-name {
    font-size: 2.5rem;
    padding-left: 1rem;
    font-optical-sizing: auto;
    font-weight: bolder;
}

#title {
    text-align: center;
    font-size: 500%;
    padding-bottom: 0rem;
    padding-top: 1rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: white;
    text-shadow: 5px 2px rgb(83, 55, 3);
}
/*
#title::before, #title::after {
    content: '';
    display: inline-block;
    margin-bottom: -2rem;
    height: 120px;
    width: 120px;
    background-image: url("../public/star.svg");
    background-size: cover;
    margin-right: 2rem;
}

#title::after {
    margin-right: 0rem;
    margin-left: 2rem;
}
*/

#instructions {
    font-size:115%;
    text-align: center;
    margin-top: -2rem;
    padding-bottom: 1rem;
}

.header {
    background: rgba(250, 94, 73, 0.705);
    background-image: url("../public/crab.svg");
    background-size: 150px 140px;
    background-repeat: repeat-x;
    background-position: 0 50%;
    align-items: center;
    color:white;
    font-family: "Pangolin", cursive;
    letter-spacing: 1px;
}
